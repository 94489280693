@import './components/background/background.scss';

html {
	--scrollbarBG: #FFFFFF;
	--thumbBG: #90A4AE;
}

body {
	// white-space: pre-wrap;
	scroll-behavior: smooth;
	scrollbar-width: thin;
	scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

// Set custom scrollbar
::-webkit-scrollbar-track {
	background: var(--scrollbarBG);
}
::-webkit-scrollbar-thumb {
	background-color: var(--thumbBG) ;
	border-radius: 6px;
	border: 3px solid var(--scrollbarBG);
}
::-webkit-scrollbar {
	width: 11px;
}

.MuiGrid-item {
	width: 100%;
}

.clickable {
	cursor: pointer;
}

.stickyContainer {
	position: -webkit-sticky; /* Safari */
  	position: sticky;
	position: -moz-sticky;
	position: -o-sticky;
	position: -ms-sticky;
}

@keyframes squareRotation {
	0% {
		-webkit-transform: translateY(-100px);
		-moz-transform: translateY(-100px);
		-ms-transform: translateY(-100px);
		transform: translateY(-100px);
	}
	50% {
		// transform: translate(0px, 50vh) rotate(300deg);
	}
	100% {
		-webkit-transform: translateY(calc(100vh + 100px)) rotate(600deg);
		-moz-transform: translateY(calc(100vh + 100px)) rotate(600deg);
		-ms-transform: translateY(calc(100vh + 100px)) rotate(600deg);
		transform: translateY(calc(100vh + 100px)) rotate(600deg);
	}
}